<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h3><v-icon>mdi mdi-cog-outline</v-icon> Configuration</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                
                <ContentHeader :description="'Theme Color'" />

                <v-radio-group 
                    row 
                    v-model="userLoggedGetters.themeColor" 
                    @change="changeTheme()"
                    color="var(--color__main)"
                >
                    <v-radio label="Dark" :value="1"></v-radio>
                    <v-radio label="Light" :value="2"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import { mapGetters } from 'vuex';

    export default ({
        
        components: {
            ContentHeader,
        },

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
        },

        methods: {
            changeTheme() {
                
                if (this.userLoggedGetters.themeColor === 1)  { //dark
                    this.$vuetify.theme.dark = true;
                }
                else { // light
                    this.$vuetify.theme.dark = false;
                }

                let params = {
                    idUser: this.userLoggedGetters.id, 
                    themeColor: this.userLoggedGetters.themeColor
                }

                this.$store.dispatch("userModule/UpdateTheme", params);
            }
        }
    })
</script>
